var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mx-4"},[_c('div',{staticClass:"contact_row d-flex just.ify-center flex-column align-center pt-4"},[_c('div',{staticClass:"card__title"},[_vm._v("Ref No")]),_c('div',[_vm._v(_vm._s(_vm.po))])]),_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('div',[_c('span',{staticClass:"mb-0 text-right text-capitalize text-truncate row__text"},[_vm._v(_vm._s(_vm.issueDate))])]),_c('div',[_c('span',{staticClass:"mb-0 text-center row__text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.total,_vm.currencyNoSymbol)))])]),_c('div',[_c('span',{staticClass:"mb-0 row__text"},[_vm._v(_vm._s(_vm.counterParty))])])]),_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('div',[_c('span',{staticClass:"mb-0 row__text"},[_vm._v(_vm._s(_vm.createdBy))])]),_c('v-chip',{staticClass:"chip__text",attrs:{"color":_vm.chipcolor,"text-color":_vm.textColor,"x-small":""}},[_vm._v(_vm._s(_vm.status))]),_c('div',{staticClass:"d-flex align-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
            'background-color': hover ? '#2bd5ae' : '',
            border: hover ? 'none' : '',
          }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"},on:{"click":function($event){return _vm.$router.push({
              path: ("/purchase-order/edit/" + _vm.quoteRef),
              query: { edit: true },
            })}}},[_vm._v(" edit ")])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
            'background-color': hover ? '#c4c9cf' : '',
            border: hover ? 'none' : '',
            color: hover ? 'white' : '#ffffff',
          }),attrs:{"dark":"","outlined":"","depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push(("/preview-purchase-order/" + _vm.quoteRef))}}},[_vm._v("view")])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }