<template>
  <div>
    <v-dialog v-model="dialog" max-width="574">
      <v-card color="#f8f7f4" class="rounded-lg">
        <v-card-title class="modal__title">
          <span>Convert Quote</span>
          <v-spacer></v-spacer>
          <v-icon
            tag="button"
            @click="dialog = false"
            class="text-bolder pa-1"
            color="#596A73"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <div class="info__container py-8 px-12">
          <p class="info__text pb-0">
            Are you sure you want to convert this quote to an invoice?
          </p>
        </div>
        <div class="mt-4">
          <div class="py-5 px-6 d-flex align-center justify-end">
            <v-btn
              @click="handleCancelConvert"
              dark
              color="#fff"
              elevation="0"
              class="text-capitalize py-md-6 px-md-6"
            >
              <v-icon color="primary">mdi-chevron-left</v-icon>
              <span class="primary--text">Back</span>
            </v-btn>
            <v-btn
              color="primary"
              class="text-capitalize py-md-6 px-md-6 ml-3"
              :loading="convertingQuote"
              @click="sentAsInvoice"
            >
              <v-icon> mdi-chevron-right </v-icon>
              <span class="ml-2">Convert</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex/dist/vuex.common.js";

export default {
  name: "saveFormModal",
  data() {
    return {
      dialog: false,
      convertingQuote: false,
      quoteHypnId: null,
    };
  },

  computed: {
    ...mapGetters({}),
  },
  methods: {
    // mapActions for toast
    ...mapActions({ showToast: "ui/showToast" }),

    openModal(quoteId) {
      this.quoteHypnId = quoteId;
      this.dialog = true;
    },

    // convert quote to invoice
    async sentAsInvoice() {
      this.convertingQuote = true;
      try {
        const payload = {
          quoteHypnId: this.quoteHypnId,
          organization_hypnID: this.$orgHypnId,
        };

        const { data } = await this.$store.dispatch(
          "quotes/convertQuoteToInvoice",
          payload
        );

        // console.log only in dev env

        if (process.env.NODE_ENV === "development") {
          console.log(data.id);
        }

        if (data.status === "success") {
          this.showToast({
            sclass: "success",
            show: true,
            message: "Quote converted successfully",
            timeout: 3000,
          });
        }

        this.$router.push(`/inbox/${data.id}`);
      } catch (error) {
        console.error(error);

        this.showToast({
          sclass: "error",
          show: true,
          message: error.message || "An error occurred",
          timeout: 3000,
        });
      } finally {
        this.convertingQuote = false;
      }
    },

    handleCancelConvert() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.modal__title {
  background: #ffffff;

  border-radius: 8px 8px 0px 0px;
}

.modal__title > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #19283d;
}

.info__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: rgba(25, 40, 61, 0.8);
}

.icon1-size {
  font-size: 32px;
}

.icon-size {
  font-size: 40px;
  font-weight: 400;
}
</style>
