<template>
  <div class="card mx-4">
    <div
      class="contact_row d-flex just.ify-center flex-column align-center pt-4"
    >
      <div class="card__title">Ref No</div>
      <div>{{ rfq }}</div>
    </div>
    <div class="d-flex align-center justify-space-around">
      <div>
        <span class="mb-0 text-right text-capitalize text-truncate row__text">{{
          issueDate
        }}</span>
      </div>
      <div>
        <span class="mb-0 text-center row__text" style="">{{
          total | currency(currencyNoSymbol)
        }}</span>
      </div>
      <div>
        <span class="mb-0 row__text">{{ counterParty }}</span>
      </div>
    </div>
    <div class="d-flex align-center justify-space-around">
      <div>
        <span class="mb-0 row__text">{{ createdBy }}</span>
      </div>
      <v-chip
        :color="chipcolor"
        :text-color="textColor"
        class="chip__text"
        x-small
        >{{ status }}</v-chip
      >
      <div class="d-flex align-center">
        <v-hover v-slot="{ hover }">
          <v-btn
            @click="
              $router.push({
                path: `/quote/edit/${quoteRef}`,
                query: { edit: true },
              })
            "
            :style="{
              'background-color': hover ? '#2bd5ae' : '',
              border: hover ? 'none' : '',
            }"
            exact-path
            depressed
            outlined
            dark
            small
            color="#2BD5AE"
            class="text-capitalize px-1 my-1 mr-1 rounded"
            style="color: var(--v-primary-base)"
          >
            edit
          </v-btn>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-btn
            @click="$router.push(`/preview-quote/${quoteRef}`)"
            dark
            outlined
            depressed
            small
            color="primary"
            :style="{
              'background-color': hover ? '#c4c9cf' : '',
              border: hover ? 'none' : '',
              color: hover ? 'white' : '#ffffff',
            }"
            class="text-capitalize px-2 my-1 rounded"
            >view</v-btn
          >
        </v-hover>
      </div>
    </div>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "allQuotesDataCard",
  props: {
    id: {
      type: Number,
    },
    index: {
      type: Number,
    },
    issueDate: {
      type: String,
    },
    total: {
      type: [String, Number],
    },
    counterParty: {
      type: String,
    },
    rfq: {
      type: String,
    },
    quoteRef: {
      type: String,
    },
    createdBy: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  mixins: [currencyFilter],
  computed: {
    chipcolor() {
      switch (this.status) {
        case "review":
          return "#E3AA1C";
        case "sent":
          return "#408ed5";
        case "rejected":
          return "#e91e63";
        default:
          return "#2BD5AE";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 200px;
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;

  .contact_row {
    width: 100%;
    font-weight: 600;
  }
}

.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}

.chip__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}

.card__title {
  font-size: 12px;
  font-weight: normal;
  font-family: "Inter";
}
</style>
