var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"px-10 py-6 my-1 d-flex align-center",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md3":""}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":""}},[_c('v-flex',{attrs:{"md5":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 text-center row__text"},[_vm._v(_vm._s(_vm.issueDate))])])]),_c('v-flex',{attrs:{"md7":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 text-center row__text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.total,_vm.currencyNoSymbol)))])])])],1)],1),_c('v-flex',{attrs:{"md5":""}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":""}},[_c('v-flex',{attrs:{"md6":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 row__text"},[_vm._v(_vm._s(_vm.counterParty))])])]),_c('v-flex',{attrs:{"md6":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 row__text"},[_vm._v(_vm._s(_vm.rfq))])])])],1)],1),_c('v-flex',{attrs:{"md4":""}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":""}},[_c('v-flex',{attrs:{"md5":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 text-truncate row__text"},[_vm._v(_vm._s(_vm.createdBy))])])]),_c('v-flex',{attrs:{"md3":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":_vm.statusIcon,"small":""}},[_vm._v("mdi-circle-medium")]),_c('span',{staticClass:"mb-0 text-capitalize text-truncate row__text"},[_vm._v(_vm._s(_vm.status))])],1)]),_c('v-flex',{attrs:{"md4":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                      'background-color': hover ? '#2bd5ae' : '',
                      border: hover ? 'none' : '',
                    }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"},on:{"click":function($event){return _vm.$router.push({
                        path: ("/quote/edit/" + _vm.quoteRef),
                        query: { edit: true },
                      })}}},[_vm._v(" edit ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
                      'background-color': hover ? '#c4c9cf' : '',
                      border: hover ? 'none' : '',
                      color: hover ? 'white' : '#ffffff',
                    }),attrs:{"dark":"","outlined":"","depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push(("/preview-quote/" + _vm.quoteRef))}}},[_vm._v("view")])]}}],null,true)})],1)])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }