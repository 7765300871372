<template>
  <v-container>
    <v-row>
      <v-col>
        <BaseTitleBar pageTitle="Procurement" class="mt-sm-4 mt-md-0">
          <DropDownMenu
            btnText="New"
            icon="file"
            height="54px"
            justify="right"
            :closeMenu="true"
          >
            <template #titleButton>
              <v-list-item-group>
                <v-list>
                  <v-list-item
                    class="d-flex align-start"
                    v-for="(item, index) in procurementMenu"
                    :key="index"
                    @click="handleMenuAction(index)"
                  >
                    <v-list-item-content>
                      <v-list-item-title tag="button" slot="activator">
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-icon small class="pt-4"> {{ item.icon }}</v-icon>
                  </v-list-item>
                </v-list>
              </v-list-item-group>
            </template>
          </DropDownMenu>
          <!-- <v-btn @click="handleCreateQuote" dark class="text-capitalize" style="
            height: 54px;
            background: var(--v-primary-base);
            box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
            border-radius: 4px;
          " :style="{
            width: `${$vuetify.breakpoint.mdAndDown ? '130px' : '200px'}`,
          }">
            <img :src="require('@/assets/pbot_icons/file.svg')" />
            <span style="
              padding-left: 12px;
              font-family: Inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              letter-spacing: 0.636364px;
              color: #ffffff;
            " :style="{
              fontSize: `${$vuetify.breakpoint.mdAndDown ? '12px' : '14px'}`,
            }">
              New Quote
            </span>
          </v-btn> -->
        </BaseTitleBar>
        <!-- quotes cards starts here -->
       <!-- <div
          class="
            container-wrapper
            d-sm-flex
            justify-center
            align-center
            py-6
            pb-md-0
            px-3
            pl-md-10
            pr-md-8
          "
        >
          <QuoteCard
            class="ml-md-5 mr-md-3 mr-sm-3"
            cardTitle="Total Quotes"
            :TotalValue="quoteSummaryObj.sent"
            accountSource="sent"
          >
          </QuoteCard>
          <QuoteCard
            class="mx-md-3 mx-sm-1"
            cardTitle="Total Value"
            :nocurrency="false"
            :amount="quoteSummaryObj.accepted"
            accountSource="accepted"
          >
          </QuoteCard>
          <QuoteCard
            class="ml-md-3 mr-md-6 ml-sm-3"
            cardTitle="Total"
            :nocurrency="false"
            :amount="quoteSummaryObj.wallet"
            accountSource="Quoted"
          >
          </QuoteCard>
        </div> -->
        <!-- quotes cards ends here -->
        <v-container class="mt-md-12">
          <v-row class="mx-md-10 pa-0">
            <v-col class="d-flex flex-column align-center pr-md-0" cols="12">
              <v-card
                elevation="6"
                v-if="$vuetify.breakpoint.mdAndUp"
                flat
                width="100%"
                style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
              >
                <v-tabs
                  slider-color="primary"
                  align-with-title
                  v-model="tab"
                  slider-size="4"
                >
                  <v-tab
                    class="mt-2"
                    v-for="item in procurementTabs"
                    :key="item.tab"
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 800;
                      font-size: 12px;
                      line-height: 15px;
                      text-transform: uppercase;
                    "
                    >{{ item.tab }}</v-tab
                  >

                  <v-spacer></v-spacer>

                  <v-btn
                    v-if="isClicked"
                    @click="toggleSearch"
                    plain
                    class="text-black mt-1 pt-4"
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 20px;
                      letter-spacing: 0.55px;
                      text-transform: uppercase;
                      color: #7f919b;
                    "
                  >
                    search
                    <v-icon small right class="pr-1"> mdi-magnify </v-icon>
                  </v-btn>
                  <v-expand-x-transition v-else>
                    <v-text-field
                      v-model="search"
                      @blur="isClicked = true && !search"
                      @input="searchDataTable"
                      class="seacrh-field mt-2 mr-2"
                      dense
                      clearable
                      autofocus
                      hide-details="true"
                      persistent-placeholder
                      placeholder="Search"
                      append-icon="mdi-magnify"
                      filled
                    >
                    </v-text-field>
                  </v-expand-x-transition>
                </v-tabs>
              </v-card>
              <v-card
                min-height="50px"
                width="100%"
                flat
                class="d-flex align-center py-4 py-md-0 px-3"
                style="background: rgba(127, 145, 155, 0.052607)"
              >
                <div class="d-block d-sm-flex align-center" style="width: 100%">
                  <!-- <div class="d-sm-flex align-center" :style="{
                    width: $vuetify.breakpoint.smAndUp ? '400px' : '',
                  }">
                    <v-select v-model="selectedDateTime" :items="dateTimeFilter" item-text="text" item-value="value"
                      placeholder="Date Filter" outlined background-color="#ffffff" hide-details="auto" dense
                      class="mb-4 mb-sm-0 pr-sm-4">
                    </v-select>
                    <v-select v-model="selectedAccountType" item-text="text" item-value="value" :items="accountTypeFilter"
                      outlined background-color="#ffffff" hide-details="auto" dense></v-select>
                  </div> -->
                  <v-spacer></v-spacer>

                  <v-hover v-slot="{ hover }" v-if="tab !== 2">
                    <v-btn
                      outlined
                      @click="alertCSVDownload"
                      color="primary"
                      class="my-4 export-btn mr-9 hover-btn"
                      :style="{
                        'background-color': hover ? '#2bd5ae' : '',
                        border: hover ? 'none' : '',
                      }"
                      elevation="2"
                      ><span class="material-icons pr-1"> import_export </span
                      ><span
                        class="primary--text"
                        style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 11px;
                          line-height: 12px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                        "
                        >Export</span
                      >
                    </v-btn>
                  </v-hover>
                </div>
              </v-card>
              <v-card width="100%" class="pb-12" elevation="6" flat>
                <component
                  v-bind:is="procurementTabs[tab].content"
                  class="ml-0"
                  :ref="procurementTabs[tab].content"
                >
                </component>
              </v-card>
            </v-col>
          </v-row>
          <!-- tabs for mobile devices -->
          <v-row
            class="px-0 mx-0"
            style="margin-top: 10vh"
            v-if="$vuetify.breakpoint.smAndDown"
          >
            <v-col class="ma-0 pa-0" cols="12">
              <v-bottom-navigation fixed class="pa-0 mx-0" dark>
                <v-tabs
                  center-active
                  class="ma-0"
                  background-color="primary"
                  v-model="tab"
                >
                  <v-tab
                    class="mt-2"
                    v-for="item in procurementTabs"
                    :key="item.tab"
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 700;
                      font-size: 12px;
                      line-height: 15px;
                      text-transform: uppercase;
                    "
                    >{{ item.tab }}</v-tab
                  >
                </v-tabs>
              </v-bottom-navigation>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseTitleBar from "@/components/BaseTitleBar.vue";
import DropDownMenu from "@/includes/DropDownMenu.vue";
//import QuoteCard from "@/pages/quotes/components/quotesCards.vue";
import acceptedQuotesDataPage from "@/pages/quotes/components/acceptedQuotesDataPage.vue";
import allQuotesDataPage from "@/pages/quotes/components/allQuotesDataPage.vue";
import allPurchaseOrderDataPage from "@/pages/quotes/components/allPurchaseOrderDataPage.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Quotes",
  components: {
    BaseTitleBar,
    DropDownMenu,
    //QuoteCard,
    allQuotesDataPage,
    acceptedQuotesDataPage,
    allPurchaseOrderDataPage,
  },
  data() {
    return {
      quoteSummaryObj: {
        sent: 235,
        accepted: 3230000,
        wallet: 3230000,
      },
      isClicked: true,
      tab: 0,
      search: "",
      loading: false,
      dateTimeFilter: [
        { text: "Date Filter", value: 0 },
        { text: "Last 24 Hours", value: 1 },
        { text: "Last 7 Days", value: 2 },
        { text: "Last 30 Days", value: 3 },
        { text: "Last 45 Days", value: 4 },
      ],
      accountTypeFilter: [
        { text: "All Users", value: "all" },
        { text: "Users", value: "user" },
        { text: "Team Members", value: "team" },
      ],
      selectedAccountType: "all",
      selectedDateTime: 0,
      selectedMenuTitle: null,

      procurementMenu: [
        {
          title: "Purchase Order",
          subtitle: "",
          icon: "mdi-text-box-outline ",
          path: "/purchase-order/new",
        },
        {
          title: "Quotes",
          subtitle: "",
          icon: "mdi-text-box-outline ",
          path: "/quote/new-quote",
        },
      ],
    };
  },
  computed: {
    //getter
    ...mapGetters({
      organizationToken: "organizations/OrganToken",
      organizationId: "auth/organizationId",
      teamMembers: "auth/getTeamMembers",
    }),

    procurementTabs() {
      const tabs = [
        { tab: "Purchase Order", content: "allPurchaseOrderDataPage" },
        { tab: "Quotes", content: "allQuotesDataPage" },
        { tab: "Accepted Quotes", content: "acceptedQuotesDataPage" },
      ];

      return tabs;
    },
  },
  methods: {
    // map Action for vuex ...
    ...mapActions({ showToast: "ui/showToast" }),

    toggleSearch() {
      this.isClicked = false;
    },
    // toast for csv download
    alertCSVDownload() {
      switch (this.tab) {
        case 0:
          this.$refs.quoteTable.downloadDataAsCSV();
          break;
        case 1:
          this.$refs.quoteTable.downloadDataAsCSV();
          break;

        default:
          console.log("others");
          break;
      }
      this.showToast({
        sclass: "success",
        show: true,
        message: "CSV downloaded!",
        timeout: 3000,
      });
    },

    handleMenuAction(index) {
      if (index === 0) {
        // push to create purchase order page
        this.$router.push({ name: "createPurchaseOrder" });
      } else if (index === 1) {
        // open make invoicew
        this.handleCreateQuote();
      }
    },

    // handle create new quote
    handleCreateQuote() {
      this.$router.push({ name: "createQuote" });
    },

    searchDataTable(e) {
      this.$refs[this.procurementTabs[this.tab].content].setSearchText(e);
      //console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
</style>
