<template>
  <v-container class="px-0 cards">
    <v-skeleton-loader type="table" :loading="isLoading">
      <v-layout
        row
        wrap
        class="align-center my-2 px-10 py-3"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-flex md3>
          <v-layout row>
            <v-flex md5>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold text-center">
                  Issue Date
                </p>
              </div>
            </v-flex>
            <v-flex md7>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold text-center">
                  Total
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- <v-flex md2>
                    <div class="d-flex align-center">
                        <p class="mb-0 pl-3 primary--text font-weight-bold">Total</p>
                    </div>
                </v-flex> -->
        <v-flex md5>
          <v-layout row>
            <v-flex md6>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold text-center">
                  Counterparty
                </p>
              </div>
            </v-flex>
            <v-flex md6>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold text-center">
                  Ref No.
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md4>
          <v-layout row>
            <v-flex md5>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold text-center">
                  Created by
                </p>
              </div>
            </v-flex>
            <v-flex md3>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold text-center">
                  Status
                </p>
              </div>
            </v-flex>
            <v-flex md4>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold text-center">
                  Action
                </p>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <div v-if="filteredQuotes && filteredQuotes.length > 0" class="mt-8">
        <div v-for="(row, index) in displayedItems" :key="index">
          <!-- card rows that is only displayed in desktops and laptop screens -->
          <allQuotesDataTableRow
            v-if="$vuetify.breakpoint.mdAndUp"
            :issueDate="row.issued"
            :status="row.status"
            :total="row.total"
            :counterParty="row.attention"
            :quoteRef="row.hypn_id"
            :createdBy="row.created_by || 'Nil'"
            :index="index"
            :id="row.id"
            :rfq="row.rfq"
          />
          <!-- these cards are only displayed in mobile and tablets -->
          <allQuotesDataCard
            v-if="$vuetify.breakpoint.smAndDown"
            :issueDate="row.issued"
            :status="row.status"
            :total="row.total"
            :counterParty="row.attention"
            :quoteRef="row.hypn_id"
            :createdBy="row.created_by || 'Nil'"
            :index="index"
            :id="row.id"
            :rfq="row.rfq"
          />
        </div>
      </div>
      <div class="d-flex justify-center align-center py-10 mt-8" v-else>
        <span class="text--primary text-h5 font-monospace"
          >No Data available</span
        >
      </div>
    </v-skeleton-loader>
    <!-- pagination component -->
    <div
      class="pagination__container px-8"
      v-if="filteredQuotes && filteredQuotes.length > 0"
    >
      <BasePagination
        :current-page="currentPage"
        :visible-pages="visiblePageValue"
        :total-pages="totalPages"
        @page-changed="handlePageChange"
      />
    </div>
  </v-container>
</template>

<script>
import allQuotesDataTableRow from "@/pages/quotes/components/allQuotesDataTableRow.vue";
import allQuotesDataCard from "@/pages/quotes/components/allQuotesDataCard.vue";
import csvDownloadMixin from "@/mixins/csvDownloadMixin";
import { mapGetters } from "vuex";
import BasePagination from "@/components/BasePagination.vue";
import pagination from "@/mixins/pagination.js";

export default {
  name: "AllQuotesDataPage",
  components: {
    allQuotesDataTableRow,
    allQuotesDataCard,
    BasePagination,
  },
  mixins: [csvDownloadMixin, pagination],
  data() {
    return {
      searchQuery: "",
      itemsPerPage: 10,
      isLoading: false,
    };
  },

  computed: {
    //vuex getters..
    ...mapGetters({
      allQuotes: "quotes/getQuotes",
      organizationToken: "organizations/OrganToken",
    }),

    // filter allQuotes for search
    filteredQuotes() {
      const searchQuery = this.searchQuery?.trim().toLowerCase(); // Optional chaining for safety
      if (!searchQuery) {
        return this.allQuotes;
      }

      return this.allQuotes.filter((quote) => {
        const matchesSearchTerm = (term, property) =>
          property.toLowerCase().includes(term);
        return searchQuery
          .split(/\s+/)
          .every((term) =>
            ["issued", "status", "total", "attention", "id", "rfq"].some(
              (property) => matchesSearchTerm(term, quote[property] || "")
            )
          );
      });
    },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredQuotes.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },
    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredQuotes &&
        this.filteredQuotes.length > this.itemsPerPage
      ) {
        let arraySubset = this.filteredQuotes?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredQuotes?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },
    // get the total pages
    totalPages() {
      return Math.ceil(this.filteredQuotes.length / this.itemsPerPage);
    },
  },
  methods: {
    setSearchText(value) {
      console.log(value);
      this.searchQuery = value;
    },
    downloadDataAsCSV() {
      // Call the csvDownload method from the mixin
      this.csvDownload(this.filteredQuotes, "allquotes_data.csv");
    },

    // get all quotes
    async getQuotes() {
      try {
        const data = await this.$store.dispatch(
          "quotes/getAllQuotes",
          this.$orgHypnId
        );
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.getQuotes();
  },
};
</script>

<style lang="scss" scoped>
.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
