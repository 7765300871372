<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="px-10 py-6 my-1 d-flex align-center"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
      >
        <v-layout row wrap class="align-center">
          <v-flex md3>
            <v-layout row class="align-center">
              <v-flex md5>
                <div class="d-flex align-center">
                  <span class="mb-0 text-center row__text" style="">{{
                    issueDate
                  }}</span>
                </div>
              </v-flex>
              <v-flex md7>
                <div class="d-flex align-center">
                  <span class="mb-0 text-center row__text" style="">{{
                    total | currency(currencyNoSymbol)
                  }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex md5>
            <v-layout row class="align-center">
              <v-flex md6>
                <div class="d-flex align-center">
                  <span class="mb-0 row__text">{{ counterParty }}</span>
                </div>
              </v-flex>
              <v-flex md6>
                <div class="d-flex align-center">
                  <span class="mb-0 row__text">{{ rfq }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex md4>
            <v-layout row class="align-center">
              <v-flex md5>
                <div class="d-flex align-center">
                  <span class="mb-0 text-truncate row__text">{{
                    createdBy
                  }}</span>
                </div>
              </v-flex>
              <v-flex md3>
                <div class="d-flex align-center">
                  <v-icon :color="statusIcon" small>mdi-circle-medium</v-icon>
                  <span class="mb-0 text-capitalize text-truncate row__text">{{
                    status
                  }}</span>
                </div>
              </v-flex>
              <v-flex md4>
                <div class="d-flex align-center">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      @click="
                        $router.push({
                          path: `/quote/edit/${quoteRef}`,
                          query: { edit: true },
                        })
                      "
                      :style="{
                        'background-color': hover ? '#2bd5ae' : '',
                        border: hover ? 'none' : '',
                      }"
                      exact-path
                      depressed
                      outlined
                      dark
                      small
                      color="#2BD5AE"
                      class="text-capitalize px-1 my-1 mr-1 rounded"
                      style="color: var(--v-primary-base)"
                    >
                      edit
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      @click="$router.push(`/preview-quote/${quoteRef}`)"
                      dark
                      outlined
                      depressed
                      small
                      color="primary"
                      :style="{
                        'background-color': hover ? '#c4c9cf' : '',
                        border: hover ? 'none' : '',
                        color: hover ? 'white' : '#ffffff',
                      }"
                      class="text-capitalize px-2 my-1 rounded"
                      >view</v-btn
                    >
                  </v-hover>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "allQuotesDataTableRow",
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },
    issueDate: {
      type: String,
    },
    total: {
      type: [String, Number],
    },
    counterParty: {
      type: String,
    },
    quoteRef: {
      type: String,
    },
    rfq: {
      type: String,
    },
    createdBy: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  mixins: [currencyFilter],
  computed: {
    statusIcon() {
      switch (this.status) {
        case "review":
          return "#E3AA1C";
        case "sent":
          return "#408ed5";
        case "rejected":
          return "#e91e63";
        default:
          return "#2BD5AE";
      }
    },
  },
};
</script>

<style scoped>
.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}

.chip__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}

.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}
</style>
